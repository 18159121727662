import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Footer = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `);
  return (
    <footer style={{ textAlign: 'center', paddingBottom: '2rem' }}>
      {data.site.siteMetadata.author} © 2021
    </footer>
  );
};

export default Footer;
