import React from "react"
import Footer from "./footer"
import Header from "./header"
import styled from "styled-components"
import "../styles/index.css"

const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  justify-content: center;
`

const ChildrenWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
`

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <Footer />
    </Wrapper>
  )
}

export default Layout
