import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const H1 = styled.h1`
  color: var(--blue);
  font-size: 3rem;
  text-decoration: underline wavy var(--blue);
  text-decoration-thickness: 3px;
  text-align: center;
  &::before {
    content: '';
    border: none;
  }
`;

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header style={{ marginBottom: '1rem' }}>
      <H1>{data.site.siteMetadata.title}</H1>
      <nav
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        <Link
          to="/"
          activeStyle={{ borderBottom: '4px solid var(--blue)' }}
          style={{ border: 'none', textDecoration: 'none' }}
        >
          Home
        </Link>
        <Link
          to="/about"
          activeStyle={{ borderBottom: '4px solid var(--blue)' }}
          style={{ border: 'none', textDecoration: 'none' }}
        >
          About me
        </Link>
        <Link
          to="/uses"
          activeStyle={{ borderBottom: '4px solid var(--blue)' }}
          style={{ border: 'none', textDecoration: 'none' }}
        >
          /uses
        </Link>
        <Link
          to="/contact"
          activeStyle={{ borderBottom: '4px solid var(--blue)' }}
          style={{ border: 'none', textDecoration: 'none' }}
        >
          Contact
        </Link>
      </nav>
    </header>
  );
};

export default Header;
